.menu-items {
    color: white !important;
    text-transform: uppercase;
    font-weight: 600;
}
.ant-drawer-header {
    background: #95100d;
}
.ant-drawer-title {
    color: aliceblue;
}
.ant-menu {
    background: #e10600;
}
.li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    background: #95290f;
}
.header {
    display: flex;
    align-items: center;
    background: #95100d;
    gap: 30px;
    justify-content: space-between;
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
.header-body {
    display: flex;
    align-items: center;
    background: #95100d;
    gap: 0px;
    justify-content: flex-start;
}
.traingle {
    width: 55px;
    height: 63px;
    position: relative;
    background: linear-gradient(120deg, #931414 50%, rgb(225, 6, 0) 48%);
}
@media (max-width: 600px) {
    .header-body {
        justify-content: space-between;
        gap: 30px;
    }
   .traingle {
    display: none;
   } 
}