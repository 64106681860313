.ant-select-selector {
    border-radius: 5px !important;
    background: linear-gradient(135deg, #8d8686 0%, #95100d 100%); 
  }
  .ant-select-selection-placeholder {
    color: #fff !important;
  }
.ant-select-dropdown {
    border-radius: 12px; /* Rounded corners for the dropdown */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Dropdown shadow */
    background: #fff; /* White dropdown background */
    padding: 10px; /* Padding around dropdown items */
}

.ant-select-item.ant-select-item-option {
    margin: 3px;
    border-radius: 10px;
    background: linear-gradient(135deg, #f8f7f7 0%, #95100d 100%); /* Gradient background */
}
.ant-select-item.ant-select-item-option:hover {
  background: linear-gradient(135deg, #eae7e7 0%, #E10600 100%); /* Gradient background */
}
.ant-select-item-option-content {
    color: #330504;
}